
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.main-banner {
    min-height: calc(100vh - 78px);
    height: auto;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: flex-start;
    &:before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        z-index: -1;
        background-image: url("/assets/images/main-banner-bg.png");
        background-color: #fff;
        background-size: 100% 100%;
        background-size: cover;
    }
}

.categories-wrapper {
    @apply flex flex-wrap justify-center gap-2 md:gap-5 md:px-10;
}

.button {
    @apply text-white w-full h-[60px] rounded-[15px] mt-10 max-w-[500px] mx-auto hover:opacity-70 transition-opacity;
    background: linear-gradient(180deg, #e73030 0%, #c50f0f 100%);

    @include lg {
        font-size: 20px;
    }
}

.offerButton {
    background: linear-gradient(180deg, #e73030 0%, #c50f0f 100%);
    border-radius: 10px;
}
.offerContainer {
    background-color: #fff !important;
    border-radius: 15px;
    h2 {
        @include md {
            text-align: left;
            margin-right: 15px;
        }
    }
}
.offerImage {
    border-right: unset !important;
}

.errorMessage {
    color: $error;
    font-size: 12px;
    text-align: center;
    padding-bottom: 20px;
    margin-top: -20px;
}
.form {
    max-width: 520px;
    margin: 0 auto;
    padding-bottom: 10px;
    z-index: 5;
    width: 100%;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    .steps-inner {
        flex-grow: 1;
        overflow: unset !important;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.street-address {
    border: 1px solid $quoto-blue !important;
    border-radius: 10px !important;
    border-bottom-width: 5px !important;
    margin-bottom: 10px;
}

.form-step-0 {
    min-height: 327px;
}

.formWrapper {
    position: relative;
    z-index: 3;
}

.step-tag {
    background-color: #02a9b5;
    width: fit-content;
    // pill
    border-radius: 100px;
    padding: 5px 10px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.mainTitle {
    font-size: 20px;
    line-height: 1.4;
    text-align: center;
    font-weight: 700;
    box-sizing: border-box;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin: 0 auto 20px;
    @media screen and (max-width: 800px) {
        max-width: 560px;
        margin-bottom: 30px;
    }
}
.form-button {
    @apply text-white w-full h-[60px] hover:opacity-70 transition-opacity;
    background: linear-gradient(180deg, #e73030 0%, #c50f0f 100%);
    border-radius: 10px !important;
    height: 50px;
    @include lg {
        font-size: 20px !important;
    }
}
.form-field {
    max-width: unset;
    margin: 0 auto;
    margin-bottom: 0.5rem;
    overflow-x: unset !important;
    // width: 90%
}
.form-field input[type="text"],
.form-field input[type="tel"],
.form-field input[type="email"],
.form-field input[type="number"] {
    font-family: "Roboto", sans-serif;
    min-height: 50px !important;
    font-size: 16px;
    border: 1px solid $quoto-blue;
    border-radius: 10px;
    border-bottom-width: 5px;
}
.form-field select {
    border: 1px solid $quoto-blue;
    border-radius: 10px;
    border-bottom-width: 5px;
    &:focus {
        border-color: $quoto-blue;
    }
    @media screen and (max-width: 767px) {
        min-height: 45px !important;
    }
}
.back-button {
    padding: 5px 15px;
    border-radius: 5px;
    left: 72px !important;
    bottom: 10px !important;
    @media screen and (max-width: 630px) {
        left: 5px !important;
    }
}
.selling-point {
    font-size: 11px;
    color: #999;
    padding-top: 10px;
}
.radio-field {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    margin-top: 10px;
    overflow-x: unset !important;

    & > div {
        // width: 100%;
        margin-bottom: 15px;
        display: flex;
        input {
            &:checked + label {
                font-weight: 500;
                background-color: $quoto-blue;

                color: #fff;
            }
        }
        label {
            background-color: #fff;

            color: #000;
            display: flex !important;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-weight: 700;
            border: 1px solid $quoto-blue;
            border-radius: 10px;
            border-bottom-width: 5px;
            &:hover {
                @include md {
                    box-shadow: 0px 4px 4px 0px #00000040;
                    transform: scale(1.03);
                    background-color: $quoto-blue;
                    color: #fff;
                }
            }
            span {
                font-size: 14px !important;
                font-weight: 400;
                margin-top: 0;
                @include md {
                    font-size: 16px !important;
                }
            }
            @include md {
                min-height: 50px;
            }
        }
        @include md {
            // margin: 0 10px 15px;
            // width: 90% !important;
            // margin-left: auto;
            // margin-right: auto;
        }
        &:last-child {
            margin-bottom: 10px !important;
        }
    }
    @include lg {
        // margin: 0 -10px;
        padding: 0 10px !important;
    }
}
.checkbox-field {
    overflow-x: unset !important;
    & > div {
        width: 100%;
        margin-bottom: 15px;
        display: flex;
        input {
            &:checked + label {
                background-color: $quoto-blue;
                color: #fff;
                & > span:first-child {
                    border: 1px solid #fff !important;
                    background-color: #fff !important;
                    svg {
                        pointer-events: none;
                        path {
                            fill: none;
                            stroke: $quoto-blue !important;
                            stroke-width: 10px;
                        }
                    }
                }
            }
        }
        label {
            &:hover {
                @include md {
                    box-shadow: 0px 4px 4px 0px #00000040;
                    transform: scale(1.03);
                    background-color: $quoto-blue;
                    color: #fff;
                    & > span:first-child {
                        border: 1px solid #fff !important;
                    }
                }
            }
            color: #000;

            display: flex;
            align-items: center;
            border: 1px solid $quoto-blue;
            border-radius: 10px;
            border-bottom-width: 5px;
            & > span:first-child {
                border-radius: 5px !important;
                border: 1px solid #bcbcbc !important;
            }
            @include md {
                min-height: 50px;
            }
            span {
                font-size: 16px;
                @include md {
                    font-size: 16px;
                }
            }
        }
        @include md {
            margin: 0 10px 15px;
            // width: 90% !important;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
.bestLawyer {
    font-size: 11px;
    color: #999;
}

.fields-wrapper {
    margin-top: 1.5rem;
}

.field-note {
    margin: 30px 0 0 0px;
}

.step-block {
    select,
    input {
        width: 100% !important;
    }
}

.step-title {
    color: $quoto-poseidon;
    text-align: center;
    font-size: 20px !important;
    font-weight: 700 !important;
    margin-bottom: 10px;
    @media screen and (max-width: 767px) {
        font-size: 16px !important;
    }
}
.label-as-step-title {
    color: $quoto-poseidon;

    text-align: center;
    font-size: 20px !important;
    font-weight: 700 !important;
    margin-bottom: 10px;
    margin-top: -10px;
    margin-bottom: 15px !important;
}
.step-description {
    text-align: center;
    font-weight: 500;
    margin-bottom: 2rem;
    font-size: 14px;
    @include lg {
        font-size: 18px;
    }
}

.outsideTitle {
    font-size: 14px;
    @include lg {
        font-size: 18px;
    }
}
.outsideBrief {
    color: #000;
    max-width: 565px;
}

.form-field input[type="date"] {
    min-width: 94%;
}
.submit-button {
    position: relative;
    overflow: hidden;
    &::before {
        content: "";
        background-color: rgba(255, 255, 255, 0.2);
        height: 100%;
        width: 3em;
        display: block;
        position: absolute;
        top: 0;
        left: -4.5em;
        transform: skewX(-45deg) translateX(-130%);
        transition: none;
        animation: blink 2.4s ease infinite;
    }
}

@keyframes blink {
    0% {
        transform: skewX(-45deg) translateX(-130%);
    }

    100% {
        transform: skewX(-45deg) translateX(40rem);
    }
}
.step-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 345px;
}

.formStepsWrapper {
    display: flex;
    will-change: transform;
    transform: translate(0%, 0px);
    transition: transform 0.2s ease;
    height: auto; // internet explorer fallback
    height: fit-content;
    overflow: hidden;
    padding-top: 30px;
    justify-content: center;
    padding-bottom: 30px;

    .stepBlock {
        width: 100%;
        flex-shrink: 0;
        position: relative;
        z-index: 50;
        height: auto; // internet explorer fallback
        height: fit-content;
        padding: 0 2px;
        flex-direction: column;
        justify-content: center;
        min-height: 345px;
        .title {
            font-size: 20px;
            font-weight: bold;
            padding-top: 20px !important;
        }
        .brief {
            max-width: 500px;
            margin: 0 auto 50px;
        }

        & > .buttons {
            width: 100%;
        }
        .back-button {
            bottom: -20px !important;
        }
    }
}

.formStepsWrapperWindow {
    display: flex;
    will-change: transform;
    transform: translate(0%, 0px);
    transition: transform 0.2s ease;
    height: auto; // internet explorer fallback
    height: fit-content;
    width: 100%;
    .stepBlock {
        width: 100%;
        flex-shrink: 0;
        position: relative;
        z-index: 50;
        height: auto; // internet explorer fallback
        height: fit-content;
        padding: 0 2px;
        flex-direction: column;
        justify-content: center;
        min-height: 345px;
        .title {
            font-size: 20px;
            font-weight: bold;
        }
        .description {
            margin: 10px 0;
        }

        .buttons {
            width: 100%;
        }
    }
}

.progress-bar {
    margin: 0 auto 20px !important;
    width: 80% !important;
}
