
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.checkbox-wrapper{
    @include sm {
        width: 180px;
        height: 130px;
    }
    height: 115px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border: 1px solid $quoto-blue;
    border-radius:  10px;
    border-bottom-width: 5px;
    color: $quoto-poseidon;
    transition: all 0.2s ease;
    background-color: #fff;

    &:hover{
        @include md {
            box-shadow: 0px 4px 4px 0px #00000040;
            transform: scale(1.1);
            background-color: $quoto-blue;
            color: #fff;
            img{
                filter:  brightness(100);
            }
            .box{
                border: 1px solid #fff;
            }
        }
    }

    input{
        display: none;
  
        &:checked + label  {
            background-color: $quoto-blue;
            color: #fff;
            .box{
                background-color: white;
                border-color: white;
                svg{
                    path {
                        stroke-dashoffset: 0;
                    }
                }
            }
            .category-icon {
                img{
                    filter: invert(1) brightness(100);
                }
            }
        }
    }

    .box {
        transition: all 0.2s ease;
        border: 1px solid #BCBCBC;
        width: 20px;
        height: 20px;
        padding: 3px;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.2s ease;
        margin-right: 15px;
        position: absolute;
        top: 10px;
        left: 10px;

        svg {
            pointer-events: none;
            path {
                fill: none;
                stroke: $quoto-blue;
                stroke-width: 10px;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-dasharray: 100;
                stroke-dashoffset: 101;
                transition: all 350ms cubic-bezier(1, 0, 0.37, 0.91);
            }
        }
    }

    .checkbox-label {
        @apply absolute inset-0 flex items-center justify-center flex-col cursor-pointer p-4;
        
        .category-icon {
            img {
                @apply mb-2; 
                width: 35px;
                height: 35px;
            }            
        }

        .category-name {
            @apply text-sm  text-center;
            @include sm {
              @apply text-base font-medium; 
            }
        }
    }
}