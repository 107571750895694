$quoto-poseidon: #143a56;
$quoto-vitamin: #ff9900;
$quoto-queen-of-the-night: #275376;
$quoto-trolley-grey: #818181;
$quoto-shadow-mountain: #585858;
$quoto-checkbox-border-color: #275376;
$quoto-blue: #5291C0;

:export {
    poseidon: $quoto-poseidon;
    vitamin: $quoto-vitamin;
    queenOfTheNight: $quoto-queen-of-the-night;
    trolleyGrey: $quoto-trolley-grey;
    shadowMountain: $quoto-shadow-mountain;
    blue: $quoto-blue;
}
